import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store';
import routes from './routes';
import downloadPage from '@/components/downloadPage.vue';
// import ability from '../services/ability';
// import defineUserAbility from '../services/ability';
// import { app } from '../main';
import ability from '../services/ability';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...routes,
    {
      path: '/download',
      name: 'download',
      component: downloadPage,
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('ugdb-session');
  const isMobileOrTablet = checkIfDeviceIsMobileOrTablet();
  const tabletNames = ['home', 'New operation'];

  // if (to.meta.authRequired) {
  //   store.dispatch('auth/check')
  //   const permissionsAuth = store.getters['ability/permissionsLoaded'];

  //   const abilityCheck  = to.meta.ability;
  //   if (permissionsAuth && abilityCheck) {
  //       const { action, subject } = abilityCheck;
  //       if (ability && !ability.can(action, subject)) {
  //         next({name: 'unauthorized'})
  //       } else {
  //         next()
  //       }
  //   }    
  // }
  
  // Vérification du token uniquement une fois au démarrage de l'application
  if (typeof store.state.token === 'undefined') {
    await store.dispatch('auth/check');
  }

  if (to.matched.some(record => record.meta.authRequired)) {
    if (isAuthenticated) {

      if (to.name === 'login') {
        next({ name: 'dashboard' });
      } else {
        if (tabletNames.includes(to.name) && isMobileOrTablet) {
          next();
        } else if (!tabletNames.includes(to.name) && isMobileOrTablet) {
          next({ name: 'home' });
        } else if (tabletNames.includes(to.name) && !isMobileOrTablet) {
          next({ name: 'dashboard' });
        } else {
          next();
        }
      }
      const abilityCheck  = to.meta.ability;
      const permissionsAuth = store.getters['ability/permissionsLoaded'];

      if (permissionsAuth && abilityCheck) {
          const { action, subject } = abilityCheck;
          if (ability && !ability.can(action, subject)) {
            next({name: 'unauthorized'})
          } else {
            if (to.name === 'login') {
              next({ name: 'dashboard' });
            } else {
              if (tabletNames.includes(to.name) && isMobileOrTablet) {
                next();
              } else if (!tabletNames.includes(to.name) && isMobileOrTablet) {
                next({ name: 'home' });
              } else if (tabletNames.includes(to.name) && !isMobileOrTablet) {
                next({ name: 'dashboard' });
              } else {
                next();
              }
            }
          }
      }
      
    } else {
      next({ name: 'login' });
    }
  } else if (isAuthenticated && to.name === 'login') {
    next({ name: 'dashboard' });
  } else {
    next();
  }
});

function checkIfDeviceIsMobileOrTablet() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export default router;
